.app-wrapper {
  position: absolute;
  overflow: auto;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;

  &--panelIsOpen {
    width: calc(100% - 340px);
  }
}

.success-title {
  margin: 0;
  font-weight: 600;
  color: #107c10;
}

.error-title {
  margin: 0;
  font-weight: 600;
  color: #a63448;
}

.warning-title {
  margin: 0;
  font-weight: 600;
  color: #a8a5a0;
}

.toast-blue-loading {
  background: #0078d4 !important;
  height: 2px !important;
}

.Toastify__toast {
  top: 0;
  border-radius: 3px;
  padding: 0 !important;

  @media only screen and (max-width: 640px) {
    min-height: 80px;
    height: auto;

    .error-content {
      margin: 15px 0 !important;
    }
  }
}

.Toastify__toast-body {
  margin: 0;
}

.Toastify__close-button {
  position: absolute;
  right: 15px;
  top: 5px;
}

.notif-wrapper {
  background: none;
  height: 100%;

  > div {
    display: flex;
    width: 100%;
  }
  display: flex;
}
